<template>
  <StepPage pageName="Imputar propostas" :pageNumber="4" />
</template>

<script>
import StepPage from '@/components/SpecialistSteps/StepPage';

export default {
  name: 'InputProposals',
  components: {
    StepPage
  }
};
</script>
